/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* styles.css に追加
  （angular/materialなどモジュールのcssが強制的に適用されてしまうものは、コンポーネントのcssに書いても効果がない場合があるため、必要に応じて別途globalのcssで定義しておく）
*/

/*--------------------------------------
spiner （スピナーのサークル部分）
-------------------------------------- */
.mat-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: #333333 !important;
}

/*--------------------------------------
matDatepicker
-------------------------------------- */
.mat-mdc-form-field {
  .mat-mdc-form-field-infix {
    width: 110px;
  }
  .mdc-icon-button {
    margin-right: 3px;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: #fff;
    opacity: unset;
  }
  .mdc-text-field {
    background-color: #fff;
  }
}
