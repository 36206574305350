@charset "utf-8";

.modal-open{
	display: inline-block;
}

.modal-box{
	position: fixed;
	z-index: 1000;
	display: none;
	width: 80%;
	height: auto;
	max-height: 80vh;
	padding: 0;
	margin: 0;
	border: 2px solid #e5e5e5;
	background: #fff;
	box-sizing: border-box;
	overflow-y: auto;
	@include mq(){
		max-width: 600px;
		max-height: 500px;
	}
}

.consent-modal-box{
	position: fixed;
	z-index: 1000;
	display: none;
	width: 100%;
	height: auto;
	max-height: 83vh;
	padding: 0;
	margin: 0;
	left: 0;
	bottom: 0;
	box-sizing: border-box;
	background-color: #5e5e5e;
	opacity: 0.8;
	color: #fff;
	@include mq(){
		max-height: 83px;
		font-size: 90%;
	}
}

.modal-close{
	position: absolute;
	top: 10px;
	right: 10px;
	display: block;
}

.modal-bg{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: none;
	width: 100%;
	height: 130%;
	background-color: rgb(0, 0, 0);
	opacity: 0.8;
	&.opaque {
		opacity: 1;
	}
}
