@charset "utf-8";

// fontsize 1rem=10px
@mixin fz($size){
	font-size: $size + px;
	font-size: calc($size / 10) + rem;
}

// responsive
$breakpoints:(
	'sm': 'screen and (max-width: 320px)',
	'md': 'screen and (min-width: 800px)',
	'lg': 'screen and (min-width: 960px)',
	'xl': 'screen and (min-width: 1024px)',
) !default;

@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)}{
		@content;
	}
}
