@charset "utf-8";

/*--------------------------------------
	common
ブレイクポイント：320px～1024px
	'sm': 'screen and (max-width: 320px)
	'md': 'screen and (min-width: 800px)
	'lg': 'screen and (min-width: 960px)
	'xl': 'screen and (min-width: 1024px)
-------------------------------------- */

/* font */
.font-a{
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
	&.bold{
		font-weight: 400;
	}
}

/* link */
.a-style{
	text-decoration: underline;
}

/* clearfix */
.cl{
	*zoom: 1;
	&:after{
		content: "";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}

/* キャリア別表示・非表示 */
.pc{display: none;}
.sp{display: block;}
@include mq(){
	.pc{display:block;}
	.sp{display: none;}
}

/* 非表示 */
.hidden-wrap{
	display: none !important;
}
