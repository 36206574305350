@charset "utf-8";

/*--------------------------------------
	other
-------------------------------------- */

#algolia_logo {
  margin-left: 10px;
  width: 50px;
  height: 10px;
  // margin-top: 5px;
  margin: 10px 10px 0 5px;
  background-image: url("/assets/algolia.svg");
  background-repeat: no-repeat;
  // float: right;
}
#addRow {
  margin-top: -5px;
  background-color: #e5e5e5;
  border: solid 1px #e5e5e5;
  z-index: -1;
}
.no_event {
  pointer-events: none;
}
input:disabled {
  color: #ccc;
}
select:disabled {
  color: #ccc;
}
button:disabled {
  opacity: 0.4;
  &.btn-cart, &.btn-guest-dl, &.btn-order {
    background-color: #b5b5b5;
  }
  &:hover{
		opacity: 0.4;
	}
}
article.info {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 30px;
  .title {
    width: 100%;
    background-color: #e5e5e5;
    padding: 10px;
  }
  span {
    &.cap {
      font-size: 85%;
      color: #999;
    }
    &.white {
      color: #fff;
    }
  }
}
.no_giftbox_bt {
  margin: 5px 0 15px 0;
}
.footer-single-bt-wrap {
  text-align: center;
}
span {
  &.square {
    border: 1px solid #999;
    padding: 1px 3px;
  }
  &.red-square {
    border: 1px solid #d34752;
    padding: 1px 3px;
  }
  &.cap {
    font-size: 85%;
    color: #999;
  }
  &.cap2 {
    line-height: 1.5em;
    color: #999;
    &.s {
      font-size: 70%;
    }
    &.l {
      font-size: 95%;
    }
    &.blank_l {
      margin-left: 5px;
    }
    &.red {
      color: #d34752;
    }
  }
  &.white {
    color: #fff;
  }
  &.red {
    color: #d34752;
  }
  &.black{
    color: #333;
  }
}

.m5 {
  margin: 5px;
  &.left {
    margin: 0 0 0 5px;
  }
  &.right {
    margin: 0 5px 0 0;
  }
}
.m10 {
  margin: 10px;
  &.left {
    margin: 0 0 0 10px;
  }
  &.right {
    margin: 0 10px 0 0;
  }
}
.mt10 {
  margin: 10px 0 0 0;
}
.mt20 {
  margin: 20px 0 0 0;
}
.mt30 {
  margin: 30px 0 0 0;
}
.mt50 {
  margin: 50px 0 0 0;
}
.p10 {
  padding: 10px;
}
.small{
  font-size: 85%;
}
.center{
  text-align: center;
}
.item_center{
  margin: 0 auto 0
}

/*--------------------------------------
	ngx-slick-carousel
-------------------------------------- */

.slick-slider {
  width: 300px;
  margin: auto;
  @include mq(){
    width: 500px;
    margin: 0;
  }
}
.nav-btn {
  position: absolute;
  height: 48px;
  width: 26px;
  cursor: pointer;
  top: 126px !important;
  @include mq(){
    top: 226px !important;
  }
}
.prev-slide.slick-disabled,
.next-slide.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}
.prev-slide {
  background: url(../img/nav-icon_prev.png) no-repeat scroll 0 0;
  left: -33px;
}
.next-slide {
  background: url(../img/nav-icon_next.png) no-repeat scroll 0 0;
  right: -28px;
}
.prev-slide:hover {
  opacity: 0.8;
}
.next-slide:hover {
  opacity: 0.8;
}
