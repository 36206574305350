/*-----------------------------
navigation
----------------------------- */
.nav-content{
	width: 80%;
	height: calc(100% - 60px);
	transition: ease .5s;
	transform: translateX(-100%);
	position: fixed;
	top: 60px;
	right: 100%;
	background: #fff;
	z-index: 998;
	&.open{
		transform: translateX(0);
		left: 0;
		overflow-y: auto;
	}
	.account{
		display: block;
		position: relative;
		padding: 8px 30px 6px 26px;
		border-bottom: 1px solid #e5e5e5;
		@include fz(16);
		color: #191919;
		@include mq(sm){
			padding: 12px 10px 10px;
		}
		.icon{
			position: relative;
			display: flex;
			justify-content: left;
			align-items: center;
			color: #000;
			&.icon-user{
				&:before{
					background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2042.2%2042%22%20style%3D%22enable-background%3Anew%200%200%2042.2%2042%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3Anone%3B%7D%20.st1%7Bfill%3A%237F7F7F%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Ccircle%20class%3D%22st0%22%20cx%3D%2220.8%22%20cy%3D%227.8%22%20r%3D%224.9%22%2F%3E%20%3Cpath%20class%3D%22st0%22%20d%3D%22M20.8%2C19c-7.7%2C0.2-9.7%2C15.3-10.1%2C19.2h20.1C30.5%2C34.3%2C28.5%2C19.2%2C20.8%2C19z%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M20.8%2C14.5c3.7%2C0%2C6.8-3%2C6.8-6.7S24.5%2C1%2C20.8%2C1s-6.7%2C3-6.7%2C6.8S17.1%2C14.5%2C20.8%2C14.5z%20M20.8%2C2.8%20c2.7%2C0%2C5%2C2.2%2C5%2C4.9s-2.2%2C5-5%2C5s-4.9-2.2-4.9-5S18.1%2C2.8%2C20.8%2C2.8z%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M20.8%2C17.2C20.8%2C17.2%2C20.8%2C17.2%2C20.8%2C17.2c-10.5%2C0.2-11.9%2C21-12%2C21.9c0%2C0.2%2C0.1%2C0.5%2C0.2%2C0.7%20c0.2%2C0.2%2C0.4%2C0.3%2C0.7%2C0.3h22.1c0.2%2C0%2C0.5-0.1%2C0.7-0.3c0.2-0.2%2C0.3-0.4%2C0.2-0.7C32.7%2C38.3%2C31.4%2C17.5%2C20.8%2C17.2z%20M10.8%2C38.3%20c0.4-4%2C2.3-19.1%2C10.1-19.2c7.7%2C0.2%2C9.7%2C15.3%2C10.1%2C19.2H10.8z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
		.name{
			margin: 0 5px 0 10px;
		}
	}
	.nav-list-header{
		display: block;
		position: relative;
		padding: 12px 30px 6px;
		@include fz(10);
		line-height: 1.2;
		color: #191919;
		border-bottom: 1px solid #e5e5e5;
		@include mq(sm){
			padding: 12px 10px 10px;
		}
		.header-list{
			display: flex;
			justify-content: space-between;
			.item{
				text-align: center;
			}
			.icon{
				position: relative;
				display: block;
				color: #000;
				min-width: 70px;
				@include mq(){
					min-width: 100px;
				}
				.badge{
					position: absolute;
					top: -6px;
					right: 11px;
					width: 16px;
					height: 16px;
					@include fz(10);
					color: #fff;
					line-height: 17px;
					border-radius: 50%;
					background: #d34752;
					text-align: center;
					@include mq(){
						top: -6px;
						right: 20px;
						width: 22px;
						height: 22px;
						@include fz(12);
						line-height: 23px;
					}
				}
				&.icon-box{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
				&.icon-bell{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
				&.icon-account{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
	.nav-list{
		display: block;
		position: relative;
		@include fz(14);
		color: #191919;
		.item{
			position: relative;
			width: 100%;
			height: auto;
			text-align: left;
			border-right: none;
			.icon{
				padding: 0 10px;
				color: #0068b7;
				&:before{
					position: relative;
					margin: 0;
				}
				&:after{
					position: relative;
					width: 0;
					height: 0;
					margin: 0;
					border-right: none;
					border-bottom: none;
				}
			}
			&.current{
				background: #fff;
				a{
					color: #191919;
				}
			}
			a{
				display: block;
				padding: 14px 10px 15px 30px;
				color: #191919;
				text-decoration: none;
				&.icon-badge{
					position: relative;
					.badge{
						position: absolute;
						top: 50%;
						left: 140px;
						width: 16px;
						height: 16px;
						margin: -8px auto 0;
						@include fz(10);
						color: #fff;
						line-height: 17px;
						border-radius: 50%;
						background: #d34752;
						text-align: center;
						z-index: 1;
					}
				}
			}
			&:first-child{
				border-top: none;
				border-left: none;
			}
		}
	}
}
