@charset "utf-8";
/*--------------------------------------
	login
--------------------------------------*/
body.login{
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.wrapper-login{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main-login{
	flex: 1 0 auto;
	width: 100%;
	padding: 0 10px;
	margin: 30px auto 0;
	text-align: center;
	word-break: break-all;
	@include mq(){
		width: 460px;
		padding: 0;
	}
	.login-box {
		margin-top: 50px;
	}
	.logo{
		img{
			width: 190px;
			max-height: 63px;
			margin: 0 auto;
		}
	}
	.site_type{
		color: #f00;
		margin: 10px;
	}
	.screen_name{
		margin-top: 25px;
	}
	.about-text{
		margin: 30px 0;
		@include mq(){
			margin: 60px 0;
		}
	}
	.text{
		margin: 10px 0;
		@include fz(16);
		@include mq(){
			margin: 10px 0;
		}
	}
	.text_s{
		margin: 10px 0;
		@include mq(){
			margin: 10px 0;
		}
	}
	.login_msg {
		white-space: pre;
		margin-bottom: 20px;
	}
}

.sns-box{
	margin-top: 45px;
	@include mq(){
		// margin-top: 200px;
		margin-top: 150px;
	}
	// a{
	// 	display: block;
	// 	width: 100%;
	// 	height: 44px;
	// 	padding: 10px;
	// 	margin-bottom: 5px;
	// 	@include fz(16);
	// 	color: #fff;
	// 	border: 1px solid #b2b2b2;
	// 	-moz-border-radius: 4px;
	// 	-webkit-border-radius: 4px;
	// 	border-radius: 4px;
	// 	@include mq(){
	// 		height: 60px;
	// 		margin-bottom: 15px;
	// 	}
	// }
}

.footer-login{
	flex-shrink: 0;
	margin: 70px auto 20px;
	@include fz(12);
	.footer-inner{
		text-align: center;
	}
}
