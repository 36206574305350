/*-----------------------------
gift-box-wrap
----------------------------- */
.gift-box-wrap{
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	color: #4c4c4c;
	@include fz(12);
	@include mq(){
		@include fz(18);
		z-index: 35;
	}
	.btn{
		width: 50px;
		height: 50px;
		text-align: center;
		@include mq(){
			width: 70px;
			height: 70px;
			cursor: pointer;
		}
		&.btn-open{
			background: rgba(0,0,0,0.8);
			-moz-border-radius: 0px;
			-webkit-border-radius: 0px;
			border-radius: 0px;
		}
		&.btn-close{
			position: absolute;
			left: 0;
			bottom: 10px;
			cursor: pointer;
		}
		img{
			width: 5px;
			margin-left: -5px;
			@include mq(){
				width: 6px;
			}
		}
	}
	.gift-box-button{
		position: fixed;
		display: flex;
		align-items: center;
		width: 100%;
		height: 50px;
		bottom: 0;
		@include mq(){
			height: 70px;
			left: 0;
		}
		.message{
			position: relative;
			display: inline-block;
			margin: 0 0 0 20px;
			padding: 8px 10px 9px;
			width: calc(100% - 80px);
			color: #fff;
			text-align: center;
			background: rgba(0, 0, 0, 0.8);
			-moz-border-radius: 4px;
			-webkit-border-radius: 4px;
			border-radius: 4px;
			box-sizing: border-box;
			cursor: pointer;
			@include mq(){
				width: 550px;
				padding: 12px;
			}
			&:before{
				content: "";
				position: absolute;
				top: 50%;
				left: -16px;
				margin-top: -8px;
				border: 8px solid transparent;
				border-right: 8px solid #000;
				opacity: 0.8;
				z-index: 2;
			}
			&:after{
				content: "";
				position: absolute;
				top: 50%;
				left: -20px;
				margin-top: -10px;
				border: 10px solid transparent;
				z-index: 1;
			}
		}
	}
	.gift-box{
		position: fixed;
		z-index: 21;
		width: 100%;
		height: 70px;
		bottom: 0;
		background: rgba(0,0,0,0.8);
		@include mq(){
			height: 90px;
			left: 0;
		}
		.gift-box-inner{
			position: relative;
			height: 70px;
			@include mq(){
				height: 90px;
			}
			.gift-box-title{
				position: absolute;
				top: -30px;
				left: 0;
				width: auto;
				height: 30px;
				overflow: hidden;
				padding: 8px 20px 5px 13px;
				@include fz(12);
				text-align: left;
				color: #fff;
				background: rgba(0,0,0,0.8);
				overflow: hidden;
				-moz-border-radius: 0px;
				-webkit-border-radius: 0px;
				border-radius: 0px;
				-webkit-border-top-right-radius: 4px;
				-moz-border-radius-topright: 4px;
				border-top-right-radius: 4px;
				.title-name{
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				@include mq(){
					top: -40px;
					height: 40px;
					@include fz(16);
				}
				&.icon-gift{
					&:before{
						content: '';
						top: 0;
						right: 0;
						display: inline-block;
						width: 23px;
						height: 22px;
						margin: 0 5px -5px 0;
						background-image: url(../img/icon_gift_w.svg);
						background-size: contain;
						background-repeat: no-repeat;
						@include mq(){
							margin: 0 8px -4px 0;
							width: 25px;
							height: 24px;
						}
					}
				}
			}
			.gift-box-img{
				position: absolute;
				top: 10px;
				left: 60px;
				width: calc(100% - 60px);
				@include mq(){
					left: 105px;
					width: calc(100% - 135px);
				}
				.slick-prev,
				.slick-next{
					@include mq(){
						width: 20px;
						height: 20px;
					}
					&.slick-disabled:before,
					&.slick-disabled:after{
						@include mq(){
							opacity: 1;
							border-top: 1px solid #fff;
							border-right: 1px solid #fff;
						}
					}
				}
				.slick-prev{
					@include mq(){
						left: -20px;
					}
				}
				.slick-next{
					@include mq(){
						right: -20px;
					}
				}
				.slick-prev:before{
					@include mq(){
						content: "";
						position: absolute;
						width: 12px;
						height: 12px;
						border-top: 1px solid #fff;
						border-right: 1px solid #fff;
						top: 0;
						bottom: 0;
						left: 4px;
						margin: auto;
						-webkit-transform: rotate(-135deg);
						-moz-transform: rotate(-135deg);
						-ms-transform: rotate(-135deg);
						-o-transform: rotate(-135deg);
						transform: rotate(-135deg);
						opacity: 1;
					}
				}
				.slick-next:before{
					@include mq(){
						content: "";
						position: absolute;
						width: 12px;
						height: 12px;
						border-top: 1px solid #fff;
						border-right: 1px solid #fff;
						top: 0;
						bottom: 0;
						right: 4px;
						margin: auto;
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
						opacity: 1;
					}
				}
				.product-box{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					.box{
						width: 50px;
						height: 50px;
						min-width: 50px;
						min-height: 50px;
						margin-right: 8px;
						@include mq(){
							width: 70px;
							height: 70px;
							min-width: 70px;
							min-height: 70px;
						}
						a{
							width: 50px;
							height: 50px;
							.item-image{
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								height: 100%;
								border: 1px solid #959394;
								overflow: hidden;
								&:hover{
									border: 1px solid #e5e5e5;
									outline: 1px solid #e5e5e5;
								}
								img{
									max-width: 100%;
									max-height: 100%;
								}
							}
						}
						.product-info{
							margin-top: 10px;
							.info-inner{
								display: flex;
								justify-content: space-between;
								align-items: center;
								.item-price{
									color: #ff1d25;
								}
								.favorite-btn{
									cursor: pointer;
									.icon-heart{
										@include fz(16);
										color: #b2b2b2;
									}
									&.favorite-item{
										.icon-heart{
											@include fz(16);
											color: #cebb86;
										}
									}
								}
							}
							.item-name{
								margin-top: 5px;
								color: #4c4c4c;
							}
						}
					}
				}
			}
		}
	}
	.gift-box-modal{
		position: fixed;
		width: 100%;
		padding: 20px 10px;
		color: #fff;
		background-color: rgba(0,0,0,0.8);
		bottom: 0;
		z-index: 35;
		@include mq(){
			left: 0;
		}
		&.gift-box-menu{
			max-height: 317px;
			padding: 50px 10px 27px;
			@include mq(){
				max-height: 410px;
				padding: 60px 10px;
			}
		}
		&.gift-box-create{
			padding: 50px 10px 27px;
			@include mq(){
				padding: 83px 10px 81px;
			}
		}
		.gift-box-modal-inner{
			position: relative;
			@include mq(){
				max-width: 1024px;
				margin: 0 auto;
			}
			.text{
				margin-top: 22px;
				@include fz(13);
				line-height: 1.8;
				color: #fff;
				@include mq(){
					@include fz(18);
				}
				&:nth-child(1){
					margin-top: 0;
				}
				a{
					color: #fff;
					&.invalid {
						pointer-events: none;
						color: #666666;
					}
				}
			}
			.gift-box-input{
				input{
					&[type="text"]{
						&.form-gift-box{
							width: 100%;
							height: 40px;
							padding: 0 10px;
							background: #fff;
							border: 1px solid #b2b2b2;
							-moz-border-radius: 4px;
							-webkit-border-radius: 4px;
							border-radius: 4px;
						}
					}
				}
			}
			.btn-close{
				position: absolute;
				top: -35px;
				right: 0;
				@include mq(){
					top: -45px;
				}
			}
			.make-wrap{
				@include mq(){
					display: flex;
					align-items: center;
				}
				.gift-box-input{
					@include mq(){
						width: calc(100% - 137px);
						margin-right: 20px;
					}
				}
			}
			.btn-wrap{
				margin-top: 15px;
				text-align: right;
				@include mq(){
					margin-top: 0;
				}
				.btn-preserve{
					width: 137px;
					padding: 10px;
					@include fz(15);
					background: #bfbfbf;
					-moz-border-radius: 4px;
					-webkit-border-radius: 4px;
					border-radius: 4px;
					color: #191919;
				}
			}
		}
	}
	.gift-box-create{
		.btn-close{
			position: absolute;
			top: -35px !important;
			right: 0;
			@include mq(){
				top: -64px !important;
			}
		}
	}
}

.is-nav-open{
	.gift-box-button{
		@include mq(){
			left: 300px;
			width: calc(100% - 300px);
			-webkit-transition: ease .5s;
			transition: ease .5s;
		}
	}
	.gift-box{
		@include mq(){
			left: 300px;
			width: calc(100% - 300px);
		}
	}
	.gift-box-modal{
		@include mq(){
			width: 100%;
			width: calc(100% - 300px);
			left: 300px;
		}
	}
}

.giftbox_modal_message{
	margin-top: 10px;
}

