@charset "utf-8";

/* base */
html{
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
	font-size: 62.5%;
	line-height: 1;
}

body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	color: #191919;
	-webkit-text-size-adjust: 100%;
}

main{
	display: block;
}

h1,h2,h3,h4,h5,dl,dt,dd,ul,ol,li,p,header,section,footer,article,nav,main,th,i{
	margin: 0;
	padding: 0;
	font-size: 100%;
}

li{
	list-style-type: none;
	display: block;
}

img{
	max-width: 100%;
	height: auto;
	border: none;
	vertical-align: middle;
}

a{
	color: #4c4c4c;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: none;
	@include mq(){
		opacity: 0.6;
	}
}

button{
	&:hover{
		opacity: 0.6;
	}
}
/* form */
input,textarea,select{
	font-size: 100%;
	outline: none;
}

input::-ms-clear{
	visibility: hidden;
}

select::-ms-expand{
	display: none;
}

input,button,textarea,select{
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	box-sizing: border-box;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-family: inherit;
	font-weight: 300;
}
