/*
 * Container style
 */
.ps{
	overflow: hidden !important;
	overflow-anchor: none;
	-ms-overflow-style: none;
	touch-action: auto;
	-ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x{
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	height: 15px;
	/* there must be 'bottom' or 'top' for ps__rail-x */
	bottom: 0px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__rail-y{
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	width: 15px;
	/* there must be 'right' or 'left' for ps__rail-y */
	right: 0;
	/* please don't change 'position' */
	position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y{
	display: block;
	background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y{
	opacity: 0.6;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x{
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, height .2s ease-in-out;
	-webkit-transition: background-color .2s linear, height .2s ease-in-out;
	height: 6px;
	/* there must be 'bottom' for ps__thumb-x */
	bottom: 2px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__thumb-y{
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, width .2s ease-in-out;
	-webkit-transition: background-color .2s linear, width .2s ease-in-out;
	width: 6px;
	/* there must be 'right' for ps__thumb-y */
	right: 2px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x{
	background-color: #999;
	height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y{
	background-color: #999;
	width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none){
	.ps{
		overflow: auto !important;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
	.ps{
		overflow: auto !important;
	}
}

/*
 * scroll
 */
.scroll-box{
	overflow-y: hidden;
	@include mq(){
		position: relative;
		width: 299px;
		height: 100%;
		overflow-y: scroll;
		z-index: 5;
	}
	.ps:hover > .ps__rail-x,
	.ps:hover > .ps__rail-y,
	.ps--focus > .ps__rail-x,
	.ps--focus > .ps__rail-y,
	.ps--scrolling-x > .ps__rail-x,
	.ps--scrolling-y > .ps__rail-y{
		opacity: 1;
	}

	.ps .ps__rail-x:hover,
	.ps .ps__rail-y:hover,
	.ps .ps__rail-x:focus,
	.ps .ps__rail-y:focus,
	.ps .ps__rail-x.ps--clicking,
	.ps .ps__rail-y.ps--clicking{
		background-color: #fff !important;
		opacity: 1;
	}
	.ps__thumb-x{
		background-color: #efefef;
		border-radius: 6px;
		transition: background-color .2s linear, height .2s ease-in-out;
		-webkit-transition: background-color .2s linear, height .2s ease-in-out;
		height: 6px;
		/* there must be 'bottom' for ps__thumb-x */
		bottom: 2px;
		/* please don't change 'position' */
		position: absolute;
	}
	.ps__thumb-y{
		background-color: #efefef;
		border-radius: 6px;
		transition: background-color .2s linear, width .2s ease-in-out;
		-webkit-transition: background-color .2s linear, width .2s ease-in-out;
		width: 6px;
		/* there must be 'right' for ps__thumb-y */
		right: 2px;
		/* please don't change 'position' */
		position: absolute;
	}
	.ps--active-x > .ps__rail-x,
	.ps--active-y > .ps__rail-y{
		background-color: rgba(0, 0, 0, 0.8);
	}
	.ps__rail-x,
	.ps__rail-y{
		opacity: 1;
	}
	.ps__rail-x{
		height: 6px;
	}
	.ps__rail-y{
		width: 6px;
		background-color: #fff;
	}
	.ps__thumb-y{
		background-color: #efefef;
		width: 6px;
	}
	.ps__rail-x:hover > .ps__thumb-x,
	.ps__rail-x:focus > .ps__thumb-x,
	.ps__rail-x.ps--clicking .ps__thumb-x{
		background-color: #efefef;
		height: 6px !important;
	}
	.ps__rail-y:hover > .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y{
		background-color: #efefef;
		width: 6px !important;
	}
	.ps--active-x > .ps__rail-x,
	.ps--active-y > .ps__rail-y{
			background-color: rgba(0, 0, 0, 0.8);
	}
	.ps__rail-y:hover > .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y{
		background-color: #efefef;
	}
	.ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y{
		opacity: 1;
	}
	.ps__rail-x:hover > .ps__thumb-x,
	.ps__rail-x:focus > .ps__thumb-x,
	.ps__rail-x.ps--clicking .ps__thumb-x{
		background-color: #efefef;
		height: 6px !important;
	}
	.ps__rail-y:hover > .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y{
		background-color: #efefef;
		width: 6px !important;
		right: 2px;
	}
	.ps .ps__rail-x:hover,
	.ps .ps__rail-y:hover,
	.ps .ps__rail-x:focus,
	.ps .ps__rail-y:focus,
	.ps .ps__rail-x.ps--clicking,
	.ps .ps__rail-y.ps--clicking{
		background-color: #fff !important;
		opacity: 1;
	}
}

.scroll-box-modal1,
.scroll-box-modal2{
	position: relative;
	width: 100%;
	// height: 113px;
	max-height: 240px;
	min-height: 113px;
	height: auto;
	overflow-y: scroll;
	@include mq(){
		max-height: 290px;
		min-height: 140px;
		height: auto;
	}
	.ps:hover > .ps__rail-x,
	.ps:hover > .ps__rail-y,
	.ps--focus > .ps__rail-x,
	.ps--focus > .ps__rail-y,
	.ps--scrolling-x > .ps__rail-x,
	.ps--scrolling-y > .ps__rail-y{
		opacity: 1;
	}

	.ps .ps__rail-x:hover,
	.ps .ps__rail-y:hover,
	.ps .ps__rail-x:focus,
	.ps .ps__rail-y:focus,
	.ps .ps__rail-x.ps--clicking,
	.ps .ps__rail-y.ps--clicking{
		background-color: rgba(0, 0, 0, 0.8) !important;
		opacity: 1;
	}
	.ps__thumb-x{
		background-color: #fff;
		border-radius: 6px;
		transition: background-color .2s linear, height .2s ease-in-out;
		-webkit-transition: background-color .2s linear, height .2s ease-in-out;
		height: 6px;
		/* there must be 'bottom' for ps__thumb-x */
		bottom: 2px;
		/* please don't change 'position' */
		position: absolute;
	}
	.ps__thumb-y{
		background-color: #fff;
		border-radius: 6px;
		transition: background-color .2s linear, width .2s ease-in-out;
		-webkit-transition: background-color .2s linear, width .2s ease-in-out;
		width: 6px;
		/* there must be 'right' for ps__thumb-y */
		right: 0;
		/* please don't change 'position' */
		position: absolute;
	}
	.ps--active-x > .ps__rail-x,
	.ps--active-y > .ps__rail-y{
		background-color: rgba(0, 0, 0, 0.8);
	}
	.ps__rail-x,
	.ps__rail-y{
		opacity: 1;
	}
	.ps__rail-x{
		height: 6px;
	}
	.ps__rail-y{
		width: 6px;
		background-color: rgba(0, 0, 0, 0.8);
	}
	.ps__thumb-y{
		width: 6px;
		background-color: #fff;
	}
	.ps__rail-x:hover > .ps__thumb-x,
	.ps__rail-x:focus > .ps__thumb-x,
	.ps__rail-x.ps--clicking .ps__thumb-x{
		background-color: #fff;
		height: 6px !important;
	}
	.ps__rail-y:hover > .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y{
		background-color: #fff;
		width: 6px !important;
	}
	.ps--active-x > .ps__rail-x,
	.ps--active-y > .ps__rail-y{
		background-color: rgba(0, 0, 0, 0.8);
	}
	.ps__rail-y:hover > .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y{
		background-color: #fff;
	}
	.ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y{
		opacity: 1;
	}
	.ps .ps__rail-x:hover,
	.ps .ps__rail-y:hover,
	.ps .ps__rail-x:focus,
	.ps .ps__rail-y:focus,
	.ps .ps__rail-x.ps--clicking,
	.ps .ps__rail-y.ps--clicking{
		background-color: #fff !important;
		opacity: 1;
	}
}
