/*-----------------------------
header
----------------------------- */
#header{
	@media print{
		display: none;
	}
	min-width: 320px;
	color: #191919;
	.header-section{
		position: static;
		min-width: 320px;
		height: auto;
		min-height: 60px;
		.header-section-inner{
			position: fixed;
			max-width: 100%;
			width: 100%;
			min-height: 60px;
			padding: 10px;
			background: #fff;
			border-bottom: 1px solid #e5e5e5;
			text-align: center;
			z-index: 20;
			.header-cont{
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 300px;
				height: 40px;
				.logo{
					max-width: 40%;
					max-height: 40px;
					margin: auto;
					a{
						display: block;
						margin: 0 auto;
						img{
							width: 95px;
							max-height: 30px;
						}
					}
				}
				.hamburger-button {
					.is-nav-open & {
						padding: 7px 15px 2px 0;
					}
					.hamburger {
						position: absolute;
						top: -5px;
						left: 30px;
						width: 36px;
						height: 40px;
						display: inline-block;
						cursor: pointer;
						transition-property: opacity, filter;
						transition-duration: 0.3s;
						transition-timing-function: linear;
						font: inherit;
						color: inherit;
						text-transform: none;
						background-color: transparent;
						border: 0;
						margin: 0;
						overflow: visible;
						z-index: 30;
						.hamburger-box {
							&:hover {
								.hamburger-inner {
									&::before {
										transform: translate3d(8px, 3px, 0) rotate(45deg) scale(0.7, 1);
									}
									&::after {
										transform: translate3d(8px, -3px, 0) rotate(-45deg) scale(0.7, 1);
									}
								}
							}
						}
						.is-nav-open & {
							.hamburger-box {
								&:hover {
									.hamburger-inner {
										&::before {
											transform: translate3d(-8px, 3px, 0) rotate(-45deg) scale(0.7, 1);
										}
										&::after {
											transform: translate3d(-8px, -3px, 0) rotate(45deg) scale(0.7, 1);
										}
									}
								}
							}
						}
					}
					.hamburger-box {
						width: 36px;
						height: 40px;
						display: inline-block;
						position: relative;
					}
					.hamburger-inner {
						display: block;
						top: 50%;
						margin-top: -2px;
						width: 30px;
						height: 2px;
						background-color: #7f7f7f;
						position: absolute;
						transition-property: transform;
						transition-duration: 0.15s;
						transition-timing-function: ease;
						&::after,
						&::before {
							width: 30px;
							height: 2px;
							background-color: #7f7f7f;
							position: absolute;
							transition-property: transform;
							transition-duration: 0.15s;
							transition-timing-function: ease;
						}
						&::after {
							content: "";
							display: block;
						}
						&::before {
							content: "";
							display: block;
							top: -10px;
						}
						&::after {
							bottom: -10px;
						}
					}
					.is-right-active .hamburger--arrow .hamburger-inner {
						&::before {
							transform: translate3d(7px, 3px, 0) rotate(45deg) scale(0.7, 1);
						}
						&::after {
							transform: translate3d(7px, -3px, 0) rotate(-45deg) scale(0.7, 1);
						}
					}
					.is-left-active.hamburger--arrow .hamburger-inner {
						&::before {
							transform: translate3d(-7px, 3px, 0) rotate(-45deg) scale(0.7, 1);
						}
						&::after {
							transform: translate3d(-7px, -3px, 0) rotate(45deg) scale(0.7, 1);
						}
					}
				}
				.header-search{
					position: absolute;
					top: 6px;
					left: 50px;
					img{
						width: 24px;
					}
				}
				.header-bell{
					position: absolute;
					top: 8px;
					left: 60px;
					img{
						width: 24px;
					}
				}
				// .header-gift{
				// 	position: absolute;
				// 	top: 8px;
				// 	right: 60px;
				// 	img{
				// 		width: 24px;
				// 	}
				// }
				// .header-cart{
				// 	position: absolute;
				// 	top: 8px;
				// 	right: 20px;
				// 	img{
				// 		width: 24px;
				// 	}
				// }
				.header-order-limit{
					position: absolute;
					top: 5px;
					right: 5px;
					@include mq(){
						top: 0;
						right: 20px;
					}
				}
				.header-dev{
					position: absolute;
					display: block;
					top: 0;
					right: 5px;
					padding-top: 1px;
					color: #d34752;
					@include fz(10);
					@include mq(){
						top: -22px;
						right: 7px;
					}
				}
				.header-order-change{
					position: absolute;
					display: block;
					top: 6px;
					right: 60px;
					width: 28px;
					height: 28px;
					padding-top: 1px;
					@include fz(10);
					line-height: 1.2;
					border: 1px solid #7f7f7f;
					background: #e5e5e5;
					letter-spacing: 0.08em;
				}
				.header-order-new{
					position: absolute;
					top: 6px;
					right: 13px;
					width: 28px;
					height: 28px;
					padding-top: 1px;
					@include fz(10);
					line-height: 1.2;
					border: 1px solid #7f7f7f;
					background: #fff;
					letter-spacing: 0.08em;
				}
				.badge{
					position: absolute;
					top: -5px;
					right: -10px;
					width: 16px;
					height: 16px;
					font-size: 10px;
					color: #fff;
					line-height: 17px;
					border-radius: 50%;
					background: #d34752;
					text-align: center;
					z-index: 1;
				}
				.badge-order{
					position: absolute;
					top: -5px;
					right: -13px;
					width: 16px;
					height: 16px;
					font-size: 10px;
					color: #fff;
					line-height: 17px;
					border-radius: 50%;
					background: #d34752;
					text-align: center;
					z-index: 1;
				}
			}
		}
		.nav-section{
			background: none;
			.backlayer{
				position: fixed;
				display: none;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #000;
				opacity: 0.8;
				z-index: 997;
			}
			.nav-button-badge{
				display: block;
				position: fixed;
				top: 18px;
				left: 18px;
				width: 20px;
				z-index: 31;
				&.open{
					.badge{
						display: none;
					}
				}
				.badge{
					position: absolute;
					top: -5px;
					right: -15px;
					width: 16px;
					height: 16px;
					font-size: 10px;
					color: #fff;
					line-height: 17px;
					border-radius: 50%;
					background: #d34752;
					text-align: center;
				}
			}
			.nav-button{
				display: block;
				position: fixed;
				top: 20px;
				left: 18px;
				width: 40px;
				height: 40px;
				cursor: pointer;
				z-index: 30;
				.nav-icon{
					position: relative;
					display: block;
					height: 1px;
					width: 20px;
					background: #7f7f7f;
					-webkit-transition: ease .5s;
					transition: ease .5s;
					z-index: 30;
				}
				.nav-icon:nth-child(1){
					top: 0;
				}
				.nav-icon:nth-child(2){
					margin: 8px 0;
				}
				.nav-icon:nth-child(3){
					top: 0;
				}
				&.open{
					.nav-icon:nth-child(1){
						top: 8px;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						background: #fff;
					}
					.nav-icon:nth-child(2){
						-webkit-transform: translateY(-50%);
						transform: translateY(-50%);
						background: #fff;
						opacity: 0;
					}
					.nav-icon:nth-child(3){
						top: -10px;
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
						background: #fff;
					}
				}
			}
		}
	}
}
