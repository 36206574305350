/*
	'sm': 'screen and (max-width: 320px)',
	'md': 'screen and (min-width: 800px)',
	'lg': 'screen and (min-width: 960px)',
	'xl': 'screen and (min-width: 1024px)',
-------------------------------------------*/

@include mq(md){
/*-----------------------------
header
----------------------------- */
#header{
	color: #191919;
	.header-section{
		min-height: 100px;
		.header-section-inner{
			min-height: 100px;
			padding: 38px 10px 0;
			.header-cont{
				display: block;
				height: auto;
				.logo{
					max-width: 190px;
					max-height: 63px;
					a{
						width: 100%;
						img{
							width: 190px;
							max-height: 63px;
							margin-top: -15px;
						}
					}
				}
				.header-search{
					top: -5px;
					left: 90px;
					img{
						width: 35px;
					}
				}
				.header-bell{
					top: -2px;
					left: 110px;
					img{
						width: 35px;
					}
				}
				.header-gift{
					top: -3px;
					right: 90px;
					img{
						width: 35px;
					}
				}
				.header-cart{
					top: -3px;
					right: 30px;
					img{
						width: 35px;
					}
				}
				.header-order-change{
					top: -3px;
					right: 90px;
					width: 34px;
					height: 34px;
					@include fz(11);
					line-height: 1.4;
				}
				.header-order-new{
					top: -3px;
					right: 30px;
					width: 34px;
					height: 34px;
					@include fz(11);
					line-height: 1.4;
				}
				.badge{
					top: -5px;
					right: -14px;
					width: 22px;
					height: 22px;
					@include fz(12);
					line-height: 23px;
				}
				.badge-order{
					top: -5px;
					right: -19px;
					width: 22px;
					height: 22px;
					@include fz(12);
					line-height: 23px;
				}
			}
		}
		.nav-section{
			.nav-button-badge{
				top: 36px;
				left: 36px;
				width: 40px;
				.badge{
					right: -18px;
					width: 22px;
					height: 22px;
					@include fz(12);
					line-height: 23px;
				}
			}
			.nav-button{
				top: 36px;
				left: 36px;
				width: 40px;
				.nav-icon{
					height: 3px;
					width: 40px;
				}
				.nav-icon:nth-child(2){
					margin: 12px 0;
				}
				&.open{
					.nav-icon:nth-child(1){
						top: 14px;
					}
					.nav-icon:nth-child(3){
						top: -16px;
					}
				}
			}
		}
	}
}

.nav-content{
	width: 300px;
	height: 100%;
	top: 100px;
	left: 0;
	transition: ease .5s;
	transform: translateX(-100%);
	background: none;
	border-right: 1px solid #e5e5e5;
	z-index: 4;
	.side-navigation{
		width: 300px;
		height: calc(100vh - 100px);
		background: #fff;
		border-right: 1px solid #e5e5e5;
		z-index: 4;
	}
	.account{
		display: block;
		position: relative;
		padding: 12px 20px 10px 23px;
		border-bottom: 1px solid #e5e5e5;
		@include fz(15);
		color: #191919;
		.icon{
			position: relative;
			display: flex;
			justify-content: left;
			align-items: center;
			color: #000;
		}
		.name{
			margin: 0 5px 0 10px;
		}
	}
	.nav-list-header{
		display: block;
		position: relative;
		padding: 12px 20px 10px;
		@include fz(11);
		line-height: 1.2;
		color: #191919;
		background: none;
		border-bottom: 1px solid #e5e5e5;
		.header-list{
			display: flex;
			justify-content: space-between;
			.item{
				text-align: center;
			}
			.icon{
				position: relative;
				display: block;
				color: #000;
				min-width: 85px;
				.badge{
					position: absolute;
					top: -6px;
					right: 12px;
					width: 22px;
					height: 22px;
					@include fz(12);
					color: #fff;
					line-height: 23px;
					border-radius: 50%;
					background: #d34752;
					text-align: center;
				}
			}
		}
	}
	.nav-list{
		display: block;
		position: relative;
		@include fz(15);
		color: #191919;
		padding: 10px 0;
		.item{
			position: relative;
			width: 100%;
			height: 100%;
			text-align: left;
			border-right: none;
			.icon{
				padding: 0 10px;
				color: #0068b7;
				&:before{
					position: relative;
					margin: 0;
				}
				&:after{
					position: relative;
					width: 0;
					height: 0;
					margin: 0;
					border-right: none;
					border-bottom: none;
				}
			}
			&.current{
				background: #fff;
				a{
					color: #191919;
				}
			}
			a{
				display: block;
				padding: 14px 10px 14px 25px;
				color: #191919;
				text-decoration: none;
				&.icon-badge{
					position: relative;
					.badge{
						position: absolute;
						top: 50%;
						left: 140px;
						width: 22px;
						height: 22px;
						@include fz(12);
						margin: -11px auto 0;
						color: #fff;
						line-height: 23px;
						border-radius: 50%;
						background: #d34752;
						text-align: center;
						z-index: 1;
					}
				}
			}
			&:first-child{
				border-top: none;
				border-left: none;
			}
		}
	}
}

a[href^="tel:"]{
	pointer-events: none;
}

}

body{
	&.is-nav-open{
		.nav-content{
			transform: translateX(0);
		}
		.main{
			left: 300px;
			max-width: calc(100% - 300px);
			margin: auto;
		}
	}
}
