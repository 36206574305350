@charset "utf-8";
/*-------------------------
	reset
--------------------------*/
html, body,
h1, h2, h3, h4, h5, h6,
a, p, span,
em, small, strong,
sub, sup,
mark, del, ins, strike,
abbr, dfn,
blockquote, q, cite,
code, pre,
ol, ul, li, dl, dt, dd,
div, section, article,
main, aside, nav,
header, hgroup, footer,
img, figure, figcaption,
address, time,
audio, video,
canvas, iframe,
details, summary,
fieldset, form, label, legend,
table, caption,
tbody, tfoot, thead,
tr, th, td{
	margin: 0;
	padding: 0;
	border: 0;
}

html{
	font-size: 62.5%;
}

body{
	@include fz(12);
	line-height: 1.4;
	@include mq(){
		@include fz(16);
	}
}

*{
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	box-sizing: border-box;
}

*:before,
*:after{
	box-sizing: inherit;
}

article, aside, footer, header, nav, section, main{
	display: block;
}

table{
	border-collapse: collapse;
	border-spacing: 0;
}

ol,ul{
	list-style: none;
}

img{
	border: none;
	vertical-align: middle;
	line-height: 0;
}

blockquote, q{
	quotes: none;
}

blockquote:after, blockquote:before,
q:after, q:before{
	content: "";
	content: none;
}

mark{
	background-color: transparent;
}

input, select{
	vertical-align: middle;
}
