@charset "utf-8";
/*--------------------------------------
	layout
--------------------------------------*/
.wrapper{
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 320px;
	height: 100%;
}

.main{
	flex: 1 0 auto;
	padding-bottom: 100px;
	word-break: break-all;
	@include mq(){
		position: absolute;
		top: 100px;
		left: 0;
		right: 0;
		width: 100%;
		min-width: 980px;
		max-width: calc(100% - 300px);
		padding-bottom: 150px;
		margin: auto;
		-o-transition: left, 0.35s ease-in-out;
		-webkit-transition: left, 0.35s ease-in-out;
		transition: left, 0.35s ease-in-out;
	}
	&.login{
		padding-bottom: 0;
	}
}

/* ランディングページ動画 */
#video_ctl {
  width: 95%;
	height: auto;
	margin: 10px;
	border: solid 1px #ccc;
	outline : none;
	text-align: center;
}

.footer{
	position: fixed;
	bottom: 0;
	flex-shrink: 0;
	z-index: 20;
	.footer-inner{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 140px;
		@include fz(12);
		background: #fff;
	}
	&.login{
		display: none;
		@include mq(){
			display: block;
		}
	}
}

.content-block{
	padding: 10px;
	@include mq(){
		max-width: 1024px;
		padding: 20px 10px;
		margin: 0 auto;
		&.product-detail{
			max-width: 1024px;
		}
	}
	&.content-block-max{
		padding: 10px 0;
		@include mq(){
			padding: 20px 10px;
		}
	}
	.content-block-inner{
		padding: 0 10px;
		@include mq(){
			padding: 0;
		}
	}
	.content-detail-inner{
		padding: 20px;
	}
	.content-detail-box{
		padding: 0 0 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #bfbfbf;
		@include mq(){
			padding: 0 0 20px;
		}
	}
}

.header-item-wrap{
	background: #e5e5e5;
}

.gift-box-detail-header{
	min-height: 50px;
	@include mq(){
		min-height: 75px;
	}
}

/* flex-box */
.flex-box{
	display: flex;
	justify-content: space-between;
	align-items: center;
	&.a-items-start{
		align-items: flex-start;
		.icon-info{
			margin-top: 0;
		}
	}
	&.a-items-end{
		align-items: flex-end;
	}
	&.j-content-start{
		justify-content: flex-start;
	}
	&.j-content-center{
		justify-content: center;
	}
	&.j-content-end{
		justify-content: flex-end;
	}
	&.flow-style{
		flex-flow: row wrap;
	}
}

.column-style{
	flex-direction: row;
	width: 100%;
	@include mq(){
		flex-direction: column;
		align-items: flex-start;
		width: 165px;
		min-width: 165px;
		margin-right: 20px;
	}
}

/* product-header */
.product-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 10px;
	@include fz(12);
	background: #fff;
	@include mq(){
		@include fz(16);
	}
	// .result{
	// }
	.view-wrap{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.view-box{
			display: flex;
			justify-content: center;
			align-items: center;
			// margin-right: 15px;
			@include mq(){
				display: none;
			}
			.view-column{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 34px;
				height: 34px;
				background: url(../img/icon_view2.png) center center no-repeat;
				background-size: 20px 20px;
				img{
					width: 20px;
				}
				&.active{
					background: url(../img/icon_view2_active.png) center center no-repeat;
					background-size: 20px 20px;
				}
			}
			.view-single{
				width: 34px;
				height: 34px;
				margin-left: -1px;
				background: url(../img/icon_view1.png) center center no-repeat;
				background-size: 20px 20px;
				img{
					width: 20px;
				}
				&.active{
					background: url(../img/icon_view1_active.png) center center no-repeat;
					background-size: 20px 20px;
				}
			}
		}
	}
}

/* inner-header */
.inner-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 28px;
	padding: 6px 15px 5px;
	margin-top: 20px;
	@include fz(12);
	background: #e5e5e5;
	@include mq(){
		align-items: flex-start;
		height: 34px;
		margin-top: 30px;
		@include fz(16);
	}
	&.a-items-center{
		@include mq(){
			align-items: center;
		}
	}
	&.j-content-end{
		justify-content: flex-end;
	}
	&.j-content-start{
		justify-content: flex-start;
	}
}

// アニメーション
@keyframes fade-in{
	0% {opacity: 0; transform: translate3d(0,-20px,0);}
	100% {opacity: 1; transform: translate3d(0,0,0);}
}

/* gift-make-block */
.gift-make-block{
	display: none;
	width: 100%;
	padding: 10px;
	opacity: 0;
	z-index: 0;
	animation-name: fade-in;
	animation-duration: 0.3s; //アニメーション時間
	animation-timing-function: ease-out; //アニメーションさせるイージング
	animation-delay: 0s; //アニメーション開始させる時間
	animation-iteration-count: 1; //繰り返し回数
	animation-direction: normal; //往復処理
	animation-fill-mode: forwards; //アニメーション後のスタイル
	@include mq(){
		padding: 15px;
		.gift-make-block-inner{
			display: flex;
			align-items: center;
			max-width: 1024px;
			margin: 0 auto;
		}
	}
	.item-list-open{
		.btn-box{
			margin-top: 30px;
		}
	}
	.make-wrap{
		button + button{
			margin-left: 8px;
		}
		@include mq(){
			display: flex;
			align-items: center;
			width: 100%;
		}
		.gift-box-input{
			input{
				&[type="text"], &[type="email"]{
					&.form-gift-box{
						width: 100%;
						height: 40px;
						padding: 0 10px;
						background: #fff;
						border: 1px solid #b2b2b2;
						-moz-border-radius: 4px;
						-webkit-border-radius: 4px;
						border-radius: 4px;
					}
				}
			}
			@include mq(){
				width: calc(100% - 273px);
				margin-right: 15px;
			}
		}
	}
	.btn-wrap{
		width: 100%;
		margin-top: 10px;
		text-align: center;
		@include mq(){
			display: flex;
			justify-content: space-between;
			width: 258px;
			margin-top: 0;
			text-align: right;
		}
	}
}

body{
	&.giftbox-open{
		.gift-make-block{
			display: block;
			opacity: 1;
		}
	}
}

/* product */
.product-block,
.product-block-multi{
	margin: 10px 0;
	&.top25 {
		margin-top: 25px;
	}
	.product-box{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		@include mq(){
			justify-content: flex-start;
		}
		// detail2用
		&.column-box{
			@include mq(){
				flex-flow: row nowrap;
				margin: 0 10px 0 40px;
			}
			.product-info-wrap{
				width: 82%;
				margin: 0 auto;
				@include mq(){
					width: auto;
					max-width: 302px;
					margin-left: 60px;
				}
			}
		}
		.box{
			width: 48%;
			margin-bottom: 30px;
			@include mq(){
				width: 21.25%;
				margin-right: 5%;
				margin-bottom: 50px;
				&:nth-child(4n){
					margin-right: 0;
				}
			}
		}
	}
	.product-detail{
		width: 80%;
		margin: 0 auto;
		@include mq(){
			width: 600px;
		}
		.product-info{
			margin: 0 auto;
			.info-inner{
				margin-top: 12px;
			}
		}
	}
}

// SP1column用
.product-block-single{
	margin: 10px 0;
	.product-box{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		width: 82.5%;
		margin: auto;
		@include mq(){
			width: 100%;
			justify-content: flex-start;
		}
		.box{
			width: 100%;
			margin-bottom: 30px;
			@include mq(){
				width: 21.25%;
				margin-right: 5%;
				margin-bottom: 30px;
				&:nth-child(4n){
					margin-right: 0;
				}
			}
			.item-image{
				position: relative;
				width: 50%;
				height: auto;
				width: 100%;
				height: 100%;
				border: 1px solid #e5e5e5;
				overflow: hidden;
				&.item-box{
					&:before{
						content: "";
						display: block;
						padding-top: 100%;
					}
				}
				.item-box-inner{
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
				}
				&:hover{
					border: 1px solid #e5e5e5;
					outline: 2px solid #e5e5e5;
				}
				img{
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}
			.protect-image{
				width: 100%;
				height: 100%;
				position: absolute;
				padding: 0;
				border: 0;
				top: 0;
				left: 0;
			}
		}
	}
	.product-detail{
		width: 80%;
		margin: 0 auto;
		@include mq(){
			width: 600px;
		}
		.product-info{
			margin: 0 auto;
			.info-inner{
				margin-top: 12px;
			}
		}
	}
}

/* giftbox-detail-wrap */
.giftbox-detail-wrap{
	@include mq(){
		display: flex;
		flex-flow: row wrap;
	}
	.item-list{
		position: relative;
		display: flex;
		align-items: center;
		padding-top: 20px;
		border-bottom: 1px solid #bfbfbf;
		cursor: default;
		@include mq(){
			width: 100%;
			padding-top: 25px;
		}
	}
}

/* list-wrap */
.list-wrap{
	margin-top: 4px;
	margin-bottom: 100px;
}
.list-wrap-border-t{
	margin-top: 20px;
	border-top: 1px solid #bfbfbf;
}
.list-wrap-border-b{
	border-bottom: none !important;
}
.item-list{
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px;
	border: none;
	border-bottom: 1px solid #e5e5e5;
	&:hover{
		@include mq(){
			cursor: pointer;
			border: 1px solid #bfbfbf;
			outline: 2px solid #bfbfbf;
			opacity: 1;
			z-index: 2;
		}
	}
	&.sendlist_all_set {
		border: none;
		padding: 12px 12px 0 12px;
		&:hover{
			@include mq(){
				cursor: pointer;
				border: none;
				outline: none;
			}
		}
	}
	&.jc-sb{
		justify-content: space-between;
	}
	&.item-list-open-wrap{
		display: block;
		cursor: default;
		.item-list-open{
			border-bottom: none;
		}
		&:hover{
			cursor: default;
			@include mq(){
				border-top: 1px solid #fff;
				border-left: 1px solid #fff;
				border-right: 1px solid #fff;
				border-bottom: 1px solid #bfbfbf;
				outline: none;
				opacity: 1;
				z-index: 2;
			}
		}
	}
	&.link-off{
		color: #b2b2b2;
	}
	&.send-list{
		&:hover{
			cursor: default;
			@include mq(){
				border-top: 1px solid #fff;
				border-left: 1px solid #fff;
				border-right: 1px solid #fff;
				border-bottom: 1px solid #bfbfbf;
				outline: none;
				opacity: 1;
				z-index: 2;
			}
		}
	}
	.lits-cont-box{
		width: 50%;
		@include mq(){
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 723px;
		}
		&.check-style{
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		&.single-style{
			width: 93%;
			@include mq(){
				width: 880px;
			}
			.gift-number-box{
				display: flex;
				flex-direction: row;
				@include mq(){
					flex-direction: column;
				}
				&.price {
					display: block;
					@include mq(){
						display: flex;
					}
				}
				.gift-number{
					margin-right: 25px;
					@include mq(){
						margin-right: 0;
					}
				}
			}
		}
		.check-detail-box{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: calc(93% - 40px);
			@include mq(){
				flex-direction: row;
				align-items: center;
				width: calc(880px - 45px);
			}
		}
		.list-name{
			@include fz(16);
			@include mq(){
				width: 256px;
				@include fz(18);
			}
		}
		.gift-number-box{
			display: flex;
			flex-direction: row;
			margin-top: 3px;
			@include mq(){
				width: 193px;
				flex-direction: column;
				@include fz(16);
			}
			&.price {
				display: block;
				@include mq(){
					display: flex;
				}
			}
			.gift-number{
				margin-top: 3px;
				margin-right: 25px;
				@include mq(){
					margin-right: 0;
				}
			}
			.gift-people{
				margin-top: 3px;
				@include mq(){
					margin-top: 0;
				}
			}
		}
		.gift-price{
			margin-top: 3px;
			@include fz(14);
			@include mq(){
				width: 228px;
				margin-top: 0;
				@include fz(21);
			}
			span{
				@include fz(12);
				@include mq(){
					@include fz(15);
				}
			}
		}
		&.sendlist{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: calc(100% - 30px);
			@include mq(){
				width: 884px;
			}
			&.column-list{
				flex-direction: column;
				align-items: flex-start;
				@include mq(){
					flex-direction: row;
					align-items: center;
				}
			}
			.list-name{
				width: 43%;
				@include mq(){
					width: 200px;
				}
			}
			.gift-data-box-wrap{
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 55%;
				@include mq(){
					display: flex;
					flex-direction: row;
					align-items: center;
				}
				&.detail-sendlist{
					@include mq(){
						width: 628px;
					}
				}
				.gift-data-box{
					width: 100%;
					margin-right: 10px;
					@include fz(12);
					color: #b2b2b2;
					@include mq(){
						width: 200px;
						margin-right: 50px;
					}
				}
				.gift-card{
					@include mq(){
						margin-right: 70px;
					}
				}
				.gift-card{
					width: 100%;
					margin-top: 5px;
					@include fz(12);
					color: #b2b2b2;
					@include mq(){
						width: 130px;
						margin-top: 0;
						margin-right: 70px;
					}
					.dl-style-w{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						dt{
							width: 4em;
						}
						dd{
							width: 70px;
						}
					}
				}
				.gift-name{
					width: 100%;
					margin-top: 5px;
					@include fz(12);
					color: #b2b2b2;
					@include mq(){
						width: 160px;
						margin-top: 0;
					}
					.name-select{
						// width: 100%;
						width: 200px;
						@include mq(){
							width: 195px;
						}
					}
					.dl-style-w{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						dt{
							width: 4em;
						}
						dd{
							width: 110px;
						}
					}
					.dl-style-w-name{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						dt{
							width: 4em;
						}
						dd{
							width: 110px;
						}
					}
					.gift-style-name{
						@include mq(){
							width: 110px;
						}
					}
				}
			}
		}
		&.sendlist-add{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: calc(100% - 120px);
			padding-right: 10px;
			@include mq(){
				width: 777px;
			}
			.list-name{
				width: 100%;
			}
		}
		&.list-column{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 93%;
			&.account-info{
				@include mq(){
					flex-direction: row;
					padding: 0;
				}
			}
			.list-name{
				width: 100%;
			}
			.list-inner-cont{
				margin: 5px 20px 0 7px;
				word-break: break-all;
				@include mq(){
					padding-top: 10px;
					margin: 5px 20px 0 0;
					width: calc(100% - 207px);
				}
			}
		}
	}
	.list-even-box{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 50%;
		@include mq(sm){
			width: 57%;
		}
		@include mq(){
			justify-content: flex-start;
			width: 236px;
		}
		&.sendlist-add{
			width: 120px;
			@include mq(){
				justify-content: flex-end;
				width: 170px;
			}
		}
		&.single-style{
			width: 7%;
			@include mq(){
				justify-content: flex-end;
				width: 79px;
			}
		}
		.lits-status-box{
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			width: 127px;
			margin-right: 10px;
			@include mq(){
				width: 176px;
				justify-content: flex-start;
			}
			&.status-order{
				color: #d34752;
			}
			&.status-cancel{
				color: #b2b2b2;
			}
		}
		.lits-change-box{
			position:relative;
			display: flex;
			justify-content: center;
			width: 30px;
			@include mq(){
				justify-content: center;
				width: 50px;
			}
		}
		.lits-change-box + .lits-change-box{
			margin-left: 20px;
			@include mq(){
				margin-left: 10px;
			}
		}
		&.sendlist{
			width: 30px;
			@include mq(){
				width: 50px;
			}
		}
		&.event_detail{
			align-self: center;
			margin-right: 10px;
		}
		.other-group{
			color: #b2b2b2;
		}
	}
	.list-box-s-l{
		width: 16%;
		max-width: 60px;
		@include mq(){
			width: 50px;
			margin-right: 10px;
		}
	}
	.list-box-s-r{
		width: 30px;
		@include mq(){
			width: 50px;
			margin-left: 20px;
		}
	}
	.list-box-m{
		width: 80%;
		@include mq(){
			width: 806px;
		}
	}
}
.giftbox-detail{
	padding-top: 12px;
	border: none;
	border-bottom: 1px solid #bfbfbf;
	.item-image{
		cursor: default;
	}
	&:hover{
		@include mq(){
			cursor: default;
			border: none;
			border-bottom: 1px solid #bfbfbf;
			outline: none;
			z-index: 2;
		}
	}
	.list-img{
		position: relative;
		width: 30%;
		min-width: 100px;
		min-height: 100px;
		overflow: hidden;
		img{
			max-width: 100%;
		}
		@include mq(){
			width: 116px;
		}
	}
	.detail-gift-box{
		margin-left: 13px;
		width: calc(70% - 30px);
		min-width: 52%;
		@include mq(){
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 764px;
		}
		.detail-gift-box-info{
			@include mq(){
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
		.detail-list-name{
			@include fz(14);
			@include mq(){
				width: auto;
			}
			&.fz-l{
				@include fz(14);
				@include mq(){
					@include fz(16);
				}
			}
		}
		.detail-gift-price{
			margin: 8px 0 6px;
			@include fz(14);
			@include mq(){
				width: 100px;
				margin-right: 20px;
			}
		}
	}
	.lits-change-box{
		width: 30px;
		text-align: center;
		@include mq(){
			width: 112px;
		}
	}
}
.item-list-open{
	padding: 0 0 20px 0;
	border-bottom: 1px solid #bfbfbf;
	@include mq(){
		padding: 20px 20px 20px 100px;
	}
	&.account-info{
		@include mq(){
			padding: 0;
		}
	}
	&.item-list-open-hed{
		padding: 20px;
		background: #fff;
	}
}
.list-inner-title{
	margin-bottom: 10px;
}
.item-list-check{
	.list-name{
		@include fz(14);
		line-height: 1.5;
	}
}

/* order-item-list */
.order-item-list{
	padding: 20px 0 20px 10px;
	border-bottom: 1px solid #bfbfbf;
	&.order-item-list-single{
		padding: 10px;
		border-bottom: none;
	}
	&.line-bold{
		padding: 30px 0 30px 10px;
		border-bottom: 2px solid #bfbfbf;
		&:first-child{
			padding: 20px 0 30px 10px;
		}
	}
	&.line-none{
		padding: 30px 0 30px 10px;
		border-bottom: none;
		&:first-child{
			padding: 20px 0 30px 10px;
		}
	}
	&.pd-style{
		padding: 0;
	}
	&.event_detail{
		display: flex;
		justify-content: space-between;
	}
}
.order-list-name{
	margin-bottom: 5px;
	@include fz(16);
	font-weight: 400;
	@include mq(){
		@include fz(18);
	}
	&.fz-l{
		@include mq(){
			@include fz(21);
		}
	}
}
.order-list-name-wrap{
	min-width: 43%;
	margin-bottom: 5px;
	@include mq(){
		padding-right: 30px;
		margin-bottom: 0;
	}
}
.order-list-name-ch{
	margin-bottom: 5px;
	@include fz(16);
	span{
		@include fz(12);
	}
	@include mq(){
		padding-right: 10px;
		margin-bottom: 0;
		@include fz(18);
		span{
			@include fz(14);
		}
	}
}
.order-list-date{
	@include mq(){
		min-width: 20%;
	}
}
.order-list-card{
	@include mq(){
		min-width: 12%;
	}
}
.order-list-name-ch-d{
	word-break: break-all;
	@include mq(){
		min-width: 20%;
	}
}
.order-list-gb{
	@include fz(12);
	span{
		@include fz(12);
	}
	@include mq(){
		@include fz(16);
	}
}
.order-list-add{
	@include fz(12);
	word-break: break-all;
	span{
		display: block;
	}
	@include mq(){
		@include fz(16);
		span{
			@include fz(14);
		}
	}
}
.order-list-tel{
	@include fz(12);
	@include mq(){
		@include fz(16);
	}
}
.order-list-title{
	width: 100%;
	padding: 5px 10px;
	background: #e5e5e5;
	@include fz(12);
	font-weight: 400;
	@include mq(){
		@include fz(14);
	}
}
.order-list-wrap-inner{
	margin-top: 15px;
	border-top: 1px solid #bfbfbf;
	&.order-list-style{
		margin-left: -10px;
	}
	&.list-size-l{
		margin-top: 0;
		border-top: none;
		border-right: none;
		.order-item-list-inner{
			padding: 0;
			border-bottom: none;
			border-left: none;
		}
	}
	.order-item-list-inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 56px;
		padding: 12px;
		border-bottom: 1px solid #bfbfbf;
		&.h-style1{
			@include mq(){
				min-height: 76px;
			}
		}
		&.list-deadline{
			margin-top: -1px;
			font-weight: 300;
			background: #f7dee0;
			border-top: 1px solid #bfbfbf;
		}
		.order-list-cont-box-odd{
			display: flex;
			align-items: center;
			width: 80%;
			@include mq(){
				width: 800px;
			}
			.mail-text{
				word-break: break-all;
			}
		}
		.order-list-cont-box-even{
			display: flex;
			justify-content: flex-end;
			width: 20%;
			padding-right: 20px;
			@include mq(){
				width: 122px;
			}
			&.j-cont-center{
				justify-content: center;
				width: 10%;
				padding-right: 0;
				@include mq(){
					width: 50px;
				}
			}
		}
		.detail-gift-box{
			width: 100%;
		}
		.list-img{
			width: 10%;
			min-width: 50px;
			min-height: 50px;
			.protect-image{
				cursor: pointer;
			}
		}
		.detail-gift-price{
			width: auto;
			margin: 1px 0;
			color: #7f7f7f;
		}
		.detail-gift-maker{
			color: #7f7f7f;
		}
	}
}
_:-ms-lang(x)::-ms-backdrop, .order-list-wrap-inner .order-item-list-inner.h-style1{
	@include mq(){
		height: 76px;
	}
}
.list-ind-box{
	width: 100%;
	padding: 20px 10px 20px 20px;
	&.change-item{
		background: #f7dee0;
	}
	.list-ind-box-inner{
		display: flex;
		flex-direction: column;
		@include mq(){
			flex-direction: row;
			align-items: center;
		}
	}
}

/* input-box */
.input-box-wrap{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 7px;
	@include mq(){
		flex-direction: row;
	}
	.input-box-title{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 180px;
		padding-top: 10px;
		margin-right: 5px;
		@include mq(){
			justify-content: space-between;
			align-items: center;
		}
		&.input-box-title-style2{
			justify-content: space-between;
			width: 100%;
			@include mq(){
				justify-content: flex-start;
				align-items: center;
				width: 165px;
				min-width: 165px;
				margin-right: 20px;
			}
		}
	}
	.input-box{
		width: 100%;
	}
}

/* info-box-wrap */
.info-box-wrap{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 12px 10px;
	border-bottom: 1px solid #bfbfbf;
	@include mq(){
		flex-direction: row;
		align-items: center;
		padding: 20px 10px;
		margin: 0;
	}
	.info-title{
		font-weight: 400;
		color: #191919;
		@include mq(){
			min-width: 170px;
			margin-right: 20px;
			font-weight: 300;
		}
	}
	.info-detail{
		margin-top: 5px;
		@include fz(14);
		color: #4c4c4c;
		@include mq(){
			margin-top: 0;
			@include fz(16);
		}
	}
}

/* order-info-box */
.list-deadline{
	margin-top: -1px;
	border-top: 1px solid #bfbfbf;
}
.list-fz-size-s{
	@include mq(){
		@include fz(13);
	}
}
.order-info-box{
	display: flex;
	align-items: center;
	width: 100%;
	&.order-info-style{
		justify-content: space-between;
	}
	.lits-cont-box-odd{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 67%;
		@include mq(){
			flex-direction: row;
			align-items: center;
			min-width: 700px;
		}
		.orderer-box{
			width: 100%;
			padding-right: 10px;
			@include mq(){
				flex-direction: row;
				align-items: center;
				min-width: 500px;
				padding-right: 50px;
			}
			.wed-name{
				padding: 5px 0;
				@include fz(16);
				@include mq(){
					@include fz(18);
				}
				p{
					overflow: hidden;
					-o-text-overflow: ellipsis;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
		.order-detail-box{
			@include mq(){
				min-width: 200px;
			}
		}
		&.w-style-s{
			.order-detail-box{
				padding-right: 10px;
			}
			@include mq(){
				min-width: 560px;
				.orderer-box{
					min-width: 310px;
				}
				.order-detail-box{
					min-width: 250px;
					padding-right: 0;
				}
			}
		}
	}
	.lits-cont-box-even{
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		width: 33%;
		@include mq(){
			flex-direction: row;
			width: 269px;
		}
		.state-box{
			display: flex;
			flex-direction: column;
			align-items: center;
			@include mq(){
				width: 50%;
			}
			.icon-note{
				font-weight: 400;
				.text-red{
					padding-top: 2px;
				}
				+ .state-change{
					margin-top: 7px;
				}
			}
			+ .state-box{
				margin-top: 7px;
				@include mq(){
					margin-top: 0;
				}
			}
			&.w-style-s{
				@include mq(){
					width: 30%;
				}
			}
			&.w-style-l{
				@include mq(){
					width: 70%;
				}
			}
			&.right-style{
				@include mq(){
					flex-direction: row;
					justify-content: flex-end;
				}
			}
		}
		&.w-style-l{
			@include mq(){
				min-width: 374px;
				.state-box{
					align-items: flex-end;
				}
			}
			.state-box{
				@include mq(){
					width: 37%;
				}
				+ .state-box{
					+ .state-box{
						@include mq(){
							width: 26%;
						}
					}
				}
			}
		}
		&.even-style{
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			width: 140px;
			@include mq(){
				flex-direction: row;
				align-items: center;
				width: 269px;
			}
		}
	}
}

/* faq */
.title-content{
	&.faq-tit{
		margin-bottom: 40px;
	}
}
.faq-title{
	padding: 0 10px 20px 10px;
	margin-top: 20px;
	@include fz(15);
	font-weight: 400;
	border-bottom: 1px solid #bfbfbf;
	cursor: pointer;
	@include mq(){
		padding: 0 15px 20px;
		margin-top: 20px;
		@include fz(16);
	}
}
ul.faq-content{
	display: none;
	padding: 0 10px;
	li{
		cursor: default;
		&.faq-q-wra{
			cursor: pointer;
		}
	}
}
.faq-box{
	padding: 10px 0 12px;
	border-bottom: 1px dashed #e5e5e5;
	@include mq(){
		padding: 10px 0 12px;
		margin: 0 10px;
	}
	&.cont-last{
		margin-bottom: 40px;
	}
}
.faq{
	width: 100%;
	cursor: pointer;
	.faq-accordion{
		display: flex;
		align-items: flex-start;
		@include mq(){
			margin: 0 5px;
		}
	}
	.faq-content-title{
		width: 20px;
		@include fz(17);
		@include mq(){
			width: 35px;
			@include fz(22);
		}
	}
	.faq-content-text{
		width: calc(100% - 30px);
		padding-top: 4px;
		@include fz(14);
		line-height: 1.6;
		@include mq(){
			width: calc(100% - 35px);
			@include fz(16);
		}
		img{
			margin-top: 15px;
			@include mq(){
				margin-top: 20px;
			}
		}
		.column-box{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			@include mq(){
				flex-direction: row;
			}
			.img-left{
				display: flex;
				flex-direction: column;
				width: 100%;
				@include mq(){
					width: 50%;
					margin-right: 30px;
				}
				&.img-left-1{
					@include mq(){
						width: 398px;
					}
					img{
						@include mq(){
							width: 100%;
						}
					}
					_:-ms-lang(x)::-ms-backdrop, img{
						width: 398px;
						height: 270px;
					}
				}
				&.img-left-2{
					@include mq(){
						width: 550px;
					}
					img{
						@include mq(){
							width: 100%;
						}
					}
					_:-ms-lang(x)::-ms-backdrop, img{
						width: 550px;
						height: 493px;
					}
				}
			}
			.img-right{
				display: flex;
				flex-direction: column;
				width: 100%;
				@include mq(){
					width: 50%;
				}
				&.img-right-1{
					@include mq(){
						width: 398px;
					}
					img{
						@include mq(){
							width: 100%;
						}
					}
					_:-ms-lang(x)::-ms-backdrop, img{
						width: 398px;
						height: 245px;
					}
				}
				&.img-right-2{
					@include mq(){
						width: 246px;
					}
					img{
						@include mq(){
							width: 100%;
						}
					}
					_:-ms-lang(x)::-ms-backdrop, img{
						width: 246px;
						height: 438px;
					}
				}
			}
		}
		.column-text{
			@include fz(12);
		}
	}
	.faq-q{
		padding: 0 0 5px 0;
		@include mq(){
			&:hover{
				opacity: 0.6;
				cursor: pointer;
			}
		}
	}
	.faq-a{
		padding: 10px 0 5px 13px;
		@include mq(){
			padding: 10px 0 0 28px;
		}
	}
}

/* howto */
.title-content{
	&.howto-tit{
		margin-bottom: 40px;
	}
}
.howto-title{
	padding: 0 10px 20px 10px;
	margin-top: 20px;
	@include fz(15);
	font-weight: 400;
	border-bottom: 1px solid #bfbfbf;
	cursor: pointer;
	@include mq(){
		padding: 0 15px 20px;
		margin-top: 20px;
		@include fz(16);
	}
}
ul.howto-content{
	display: none;
	padding: 0 10px;
	li{
		cursor: default;
		&.howto-q-wra{
			cursor: pointer;
		}
	}
}
.howto-box{
	padding: 10px 0 12px;
	margin-bottom: 40px;
	@include mq(){
		padding: 10px 0 12px;
		margin: 0 10px 40px;
	}
	.howto-text{
		@include fz(14);
		line-height: 1.6;
		@include mq(){
			@include fz(16);
		}
	}
	.howto-img{
		display: flex;
		justify-content: center;
		margin: 20px 0 50px;
		@include mq(){
			margin: 20px 0 50px;
		}
		img{
			max-width: 90%;
			@include mq(){
				max-width: 100%;
			}
		}
		.sp{
			display: block;
			@include mq(){
				display: none;
			}
		}
		.pc{
			display: none;
			@include mq(){
				display: block;
			}
		}
	}
}

/* content-footer */
.content-footer-wrap{
	@media print {
		display: none;
	}
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	z-index: 996;
	.content-footer{
		position: fixed;
		left: 0;
		width: 100%;
		padding: 12px 15px;
		color: #191919;
		background-color: #e5e5e5;
		bottom: 0;
		.content-footer-inner{
			&.center {
				justify-content: center; }
			@include mq(){
				max-width: 1024px;
				margin: 0 auto;
			}
			.icon-link-text{
				margin-top: -2px;
			}
			.box-even{
				margin-left: 10px;
			}
		}
	}
}
.is-nav-open{
	.content-footer{
		@include mq(){
			left: 300px;
			width: calc(100% - 300px);
		}
	}
}

.giftbox-detail-text{
	margin-bottom: 20px;
	@include mq(){
		margin-bottom: 30px;
	}
}

.content-odd{
	margin-right: 20px;
}

.not-select{
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.drag-item{
	width: 100px;
	height: 100px;
	position: absolute;
	z-index: 9999;
	height: 100px;
	width: 100px;
	pointer-events: none;
}

// modal用
.about-box{
	position: relative;
	padding: 10px;
	background: #fff;
	@include mq(){
		padding: 40px;
	}
	.title{
		padding-bottom: 15px;
		@include fz(15);
		color: #191919;
		border-bottom: 1px solid #e5e5e5;
		@include mq(){
			@include fz(18);
		}
	}
	.modal-cont-title{
		margin: 30px 0 15px;
		@include fz(15);
		@include mq(){
			@include fz(18);
		}
	}
	.logo-image{
		margin: 0 auto;
		text-align: center;
		img{
			width: 100%;
		}
	}
	.ex-image{
		margin: 20px auto 0;
		text-align: center;
		img{
			width: 100%;
		}
	}
	.thanks-card-pattern{
		.thanks-card-name{
			margin-top: 20px;
			p{
				text-align: right;
			}
		}
	}
}

.modal-content{
	padding: 20px 15px;
	@include mq(){
		padding: 30px;
		&.consent {
			padding: 15px;
		}
	}
}

.confirmation-box{
	padding: 30px 20px 10px;
	background: #fff;
	@include mq(){
		padding: 40px 40px 10px;
	}
}

.t-center{
	text-align: center;
}

.fixed{
	position: fixed;
}

.page {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin: 15px 10px 0 0;
	.no {
		margin: 0 5px;
		cursor: pointer;
		&.current {
			font-weight: bold;
		}
	}
}
.menu_part {
	margin: 15px 0;
	border-bottom: 1px solid #e5e5e5;
}

.m5 {
  margin: 5px;
  &.left {
    margin: 0 0 0 5px;
  }
  &.right {
    margin: 0 5px 0 0;
  }
  &.t {
    margin: 5px 0 0 0;
  }
  &.b {
		margin: 0 0 30px 0;
	}
}
.m10 {
  margin: 10px;
  &.left {
    margin: 0 0 0 10px;
  }
  &.right {
    margin: 0 10px 0 0;
  }
  &.t {
    margin: 10px 0 0 0;
  }
  &.b {
		margin: 0 0 10px 0;
	}
}
.m15{
	margin: 15px;
	&.l {
		margin: 0 0 0 15px;
	}
	&.t {
		margin: 15px 0 0 0;
	}
	&.b {
		margin: 0 0 15px 0;
	}
}
.m20{
	margin: 20px;
	&.l {
		margin: 0 0 0 20px;
	}
	&.t {
		margin: 20px 0 0 0;
	}
	&.b {
		margin: 0 0 20px 0;
	}
}
.m30{
	margin: 30px;
	&.l {
		margin: 0 0 0 30px;
	}
	&.t {
		margin: 30px 0 0 0;
	}
	&.b {
		margin: 0 0 30px 0;
	}
}
.m50{
	margin: 50px;
	&.l {
		margin: 0 0 0 50px;
	}
	&.t {
		margin: 50px 0 0 0;
	}
}
