@charset "utf-8";

/* search */
.icon-search-header{
	width: 26px;
	height: 26px;
	stroke-width: 0;
	@include mq(){
		width: 37px;
		height: 37px;
	}
}

/* giftbox */
.icon-giftbox-header{
	width: 24px;
	height: 23px;
	stroke-width: 0;
	@include mq(){
		width: 34px;
		height: 33px;
	}
}

.icon-giftbox-nav{
	width: 27px;
	height: 27px;
	margin-top: -3px;
	margin-bottom: 3px;
	stroke-width: 0;
}

.icon-giftbox-modal{
	width: 26px;
	height: 25px;
	margin-right: 5px;
	margin-bottom: -5px;
	stroke-width: 0;
}

/* cart */
.icon-cart-header{
	width: 25px;
	height: 24px;
	stroke-width: 0;
	@include mq(){
		width: 35px;
		height: 35px;
	}
}

.icon-cart-nav{
	width: 27px;
	height: 27px;
	margin-top: -3px;
	margin-bottom: 3px;
	stroke-width: 0;
}

/* user */
.icon-user{
	width: 20px;
	height: 27px;
	stroke-width: 0;
}

.icon-account{
	width: 20px;
	height: 27px;
	margin-top: -5px;
	margin-bottom: 3px;
	stroke-width: 0;
}

/* bell */
.icon-bell{
	width: 24px;
	height: 27px;
	margin-top: -5px;
	margin-bottom: 3px;
	stroke-width: 0;
}

/* edit */
.btn-edit{
	svg{
		width: 18px;
		height: 18px;
	}
}

/* trash */
.btn-trash{
	svg{
		width: 19px;
		height: 22px;
	}
}

/* person */
.icon-plus{
	svg{
		width: 22px;
		height: 22px;
	}
}

svg path.icon-color{
	stroke: #7f7f7f;
	fill: #7f7f7f;
}

svg path.icon-color-w{
	stroke: #fff;
	fill: #fff;
}

svg path.icon-color-r{
	stroke: #d34752;
	fill: #d34752;
}
