@charset "utf-8";

/*-----------------------------
form
----------------------------- */
/* login */
.login-box{
	.id,
	.password{
		width: 100%;
		height: 44px;
		padding: 10px;
		margin-bottom: 10px;
		background: #fff;
		@include mq(){
			height: 60px;
			margin-bottom: 15px;
		}
	}
	.signin{
		display: block;
		width: 100%;
		height: 44px;
		padding: 10px;
		@include fz(16);
		color: #b2b2b2;
		border: 1px solid #b2b2b2;
		@include mq(){
			height: 60px;
			margin-bottom: 20px;
			@include fz(18);
		}
	}
}

/* search-block */
.search-block-open{
	.search-block{
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

.search-block{
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	height: 75px;
	&.item{
		height: 245px;
	}
	max-height: 83%;
	background: rgba(221,221,221,0.9);
	padding: 10px;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	z-index: 5;
	@include mq(){
		top: 100px;
		height: 70px;
		&.item{
			height: 120px;
		}
		.search-block-inner{
			// display: flex;
			align-items: center;
			max-width: 1024px;
			margin: 0 auto;
			&.item{
				display: flex;
				justify-content: space-between;
			}
		}
	}
	&.mng{
		height: auto;
		&.order {
			overflow-y: scroll;
		}
		.search-block-inner{
			display: block;
			&.item{
				display: flex;
				align-items: center;
				max-width: 1024px;
				margin: 0 auto;
				justify-content: space-between;
			}
			.input-box-wrap{
				flex-direction: row;
				padding: 7px 0;
				.input-box-wrap-inner{
					display: flex;
					flex-direction: column;
					width: calc(100% - 72px);
					@include mq(){
						flex-direction: row;
						align-items: center;
						width: calc(100% - 106px);
					}
					.checkbox-wrap{
						height: 20px;
						margin-top: 7px;
						margin-left: 0;
						@include mq(){
							width: 250px;
							height: auto;
							margin-top: 0;
						}
					}
					.select-wrap{
						max-width: 293px;
						@include mq(){
							width: 297px;
							max-width: 297px;
						}
					}
				}
			}
			.input-box{
				@include mq(){
					width: 330px;
				}
				.calendar-input{
					.form-calendar{
						max-width: 130px;
					}
				}
			}
			.search-title{
				width: 72px;
				padding-right: 10px;
				@include mq(){
					width: 106px;
					padding-top: 10px;
				}
			}
			.unit{
				padding: 0 10px;
			}
		}
	}
}

/* search-input */
.search-input{
	position: relative;
	@include mq(md){
		width: 42%;
	}
	@include mq(lg){
		width: 47%;
	}
	@include mq(xl){
		width: 55%;
	}
	.form-search{
		width: 100%;
		height: 40px;
		padding: 0 10px;
		background: #fff;
		border: 1px solid #b2b2b2;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		@include mq(){
			height: 44px;
		}
		&.mng-style{
			margin-bottom: 10px;
		}
	}
	.icon-search{
		position: absolute;
		top: 0;
		right: 10px;
		height: 32px;
		color: #989898;
		font-size: 20px;
		@include mq(){
			height: 44px;
		}
		&:before{
			content: '';
			top: 0;
			right: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 8px 0 0 0;
			background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2037%2037.3%22%20style%3D%22enable-background%3Anew%200%200%2037%2037.3%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3Anone%3B%7D%20.st1%7Bfill%3A%23b2b2b2%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Ccircle%20class%3D%22st0%22%20cx%3D%2216%22%20cy%3D%2216%22%20r%3D%2214%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M36.7%2C35.6l-8.9-8.9C30.4%2C23.9%2C32%2C20.1%2C32%2C16c0-8.8-7.2-16-16-16S0%2C7.2%2C0%2C16s7.2%2C16%2C16%2C16%20c4%2C0%2C7.6-1.5%2C10.4-3.9l8.9%2C8.9c0.2%2C0.2%2C0.5%2C0.3%2C0.7%2C0.3s0.5-0.1%2C0.7-0.3C37.1%2C36.6%2C37.1%2C35.9%2C36.7%2C35.6z%20M2%2C16C2%2C8.3%2C8.3%2C2%2C16%2C2%20s14%2C6.3%2C14%2C14s-6.3%2C14-14%2C14S2%2C23.7%2C2%2C16z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
			background-size: contain;
			background-repeat: no-repeat;
			@include mq(){
				width: 28px;
				height: 28px;
			}
		}
	}
}

/* search-gift-input */
.search-gift-input{
	position: relative;
	margin: 10px 0 0;
	@include mq(){
		width: 50%;
		margin: 20px 0;
	}
	.form-gift-search{
		width: 100%;
		height: 32px;
		padding: 0 10px;
		background: #fff;
		border-bottom: 1px solid #b2b2b2;
		@include mq(){
			width: 100%;
			height: 44px;
		}
	}
	.icon-search{
		position: absolute;
		top: 0;
		right: 10px;
		height: 32px;
		color: #989898;
		font-size: 20px;
		@include mq(){
			height: 44px;
		}
		&:before{
			content: '';
			top: 0;
			right: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 8px 0 0 0;
			background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2042.2%2042%22%20style%3D%22enable-background%3Anew%200%200%2042.2%2042%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3Anone%3B%7D%20.st1%7Bfill%3A%237F7F7F%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Ccircle%20class%3D%22st0%22%20cx%3D%2218.6%22%20cy%3D%2218.4%22%20r%3D%2214.5%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M39%2C38.3l-9.2-9.2c2.7-2.8%2C4.3-6.6%2C4.3-10.7c0-8.5-7-15.5-15.5-15.5S3.1%2C9.9%2C3.1%2C18.4s7%2C15.5%2C15.5%2C15.5%20c4%2C0%2C7.7-1.6%2C10.5-4.1l9.2%2C9.2c0.1%2C0.1%2C0.2%2C0.1%2C0.4%2C0.1s0.3%2C0%2C0.4-0.1C39.2%2C38.9%2C39.2%2C38.5%2C39%2C38.3z%20M4.1%2C18.4%20c0-8%2C6.5-14.5%2C14.5-14.5s14.5%2C6.5%2C14.5%2C14.5s-6.5%2C14.5-14.5%2C14.5S4.1%2C26.4%2C4.1%2C18.4z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
			background-size: contain;
			background-repeat: no-repeat;
			@include mq(){
				width: 28px;
				height: 28px;
			}
		}
	}
}

/* calendar-input */
.calendar-input{
	@include mq(){
		// width: 130px;
	}
	.form-calendar{
		display: block;
		width: 100%;
		padding: 10px;
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2048%2048%22%20style%3D%22enable-background%3Anew%200%200%2048%2048%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3Anone%3B%7D%20.st1%7Bfill%3A%23b2b2b2%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20class%3D%22st0%22%20d%3D%22M2%2C18v25c0%2C1.7%2C1.3%2C3%2C3%2C3h38c1.7%2C0%2C3-1.3%2C3-3V18H2z%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M43%2C5h-5V0h-5v5H14V0H9v5H5c-2.8%2C0-5%2C2.2-5%2C5v33c0%2C2.8%2C2.2%2C5%2C5%2C5h38c2.8%2C0%2C5-2.2%2C5-5V10C48%2C7.2%2C45.8%2C5%2C43%2C5z%20M43%2C46H5c-1.7%2C0-3-1.3-3-3V18h44v25C46%2C44.7%2C44.7%2C46%2C43%2C46z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
		background-size: 18px 18px;
		background-repeat: no-repeat;
		background-position: right 5px bottom 10px;
		@include mq(){
			width: 130px;
		}
		@include mq(sm){
			min-height: 30px;
			@include fz(12);
		}
	}
}

/* select style */
.gift-style{
	@include mq(sm){
		@include fz(12);
	}
}

.select-dl-style{
	display: flex;
	align-items: center;
	dt{
		margin-right: 10px;
		white-space: nowrap;
		@include fz(12);
		color: #b2b2b2;
	}
	&.dt-w-style{
		dt{
			white-space: nowrap;
			min-width: 3em;
			@include mq(){
				min-width: auto;
			}
		}
	}
	&.select-dl-column-style{
		flex-direction: column;
		align-items: flex-start;
		@include mq(){
			flex-direction: row;
			align-items: center;
		}
	}
}

/* checkbox-block */
.checkbox-block{
	margin-top: 12px;
	@include fz(14);
	// text-align: center;
	@include mq(){
		margin-top: 0;
		margin-left: 20px;
		&.item{
			margin-left: 0;
		}
		@include fz(16);
		align-self: flex-end;
	}
	.search-type{
		margin: 5px 0;
		font-size: 85%;
		color: #7f7f7f;
	}
	&.list-check{
		margin-top: 0;
		@include mq(){
			margin-top: 0;
			margin-left: 0;
		}
		.checkbox-wrap{
			display: block;
		}
		.item-label{
			padding-left: 50px;
			@include mq(){
				padding-left: 55px;
				margin-right: 0;
			}
		}
	}
}

/* checkbox */
.checkbox-wrap{
	display: inline-block;
	min-height: 28px;
	margin-left: 5px;
	@include mq(){
		height: 22px;
	}
	&:first-child{
		margin-left: 0;
	}
	label{
		display: flex;
		align-items: center;
		.header-checkbox{
			display: none;
			&:checked + .item-label{
				&:after{
					content: "";
					display: block;
					position: absolute;
					top: 2px;
					left: 7px;
					width: 7px;
					height: 12px;
					transform: rotate(45deg);
					border-bottom: 2px solid #666;
					border-right: 2px solid #666;
					@include mq(){
						top: 1px;
						left: 8px;
						width: 8px;
						height: 16px;
					}
				}
			}
		}
		.item-label{
			position: relative;
			padding-top: 1px;
			padding-left: 23px;
			@include mq(){
				padding-top: 0;
				padding-left: 35px;
				margin-right: 20px;
				&.search {
					padding-left: 25px;
					margin-right: 5px;
				}
			}
			&.disabled::before {
				background-color: #e5e5e5;
			}
			&:before{
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				border: 1px solid #b2b2b2;
				background: #fff;
				-moz-border-radius: 4px;
				-webkit-border-radius: 4px;
				border-radius: 4px;
				@include mq(){
					width: 22px;
					height: 22px;
				}
			}
		}
	}
}
.item-list-check{
	.checkbox-wrap{
		label{
			.item-label{
				&:before{
					border: 2px solid #b2b2b2;
				}
			}
		}
	}
}

.input-column{
	width: 100%;
	padding: 10px;
	margin: 2 0 2px;
	&.input-size-m{
		& +.input-column{
			margin-left: 20px;
		}
	}
}

.input-size-s{
	width: 140px !important;
}

.input-size-m{
	width: calc(50% - 10px) !important;
	@include mq(){
		width: 335px !important;
	}
}

.input-size-m-style2{
	width: 100% !important;
	@include mq(){
		width: 335px !important;
	}
}

.input-size-h{
	height: 300px !important;
	@include mq(){
		height: 250px !important;
	}
}

/* カレンダー サイズ */
div.ui-datepicker{
	font-size: 18px;
	@include mq(){
		font-size: 16px;
	}
}

/* カレンダー フォント */
.ui-datepicker{
	font-family: 'Noto Sans JP', sans-serif !important;
	font-weight: 300;
}

/* カレンダー デフォルト */
.ui-widget-content a.ui-state-default{
	border: 1px solid #bfbfbf;
	background: #fff;
}

/* カレンダー 日曜日 */
td.ui-datepicker-week-end:first-child a.ui-state-default{
	background: #f7dee0;
	color: #191919 !important;
}

/* カレンダー 土曜日 */
td.ui-datepicker-week-end:last-child a.ui-state-default{
	background: #e2f4f5;
	color: #191919 !important;
}

/* カレンダー 当日 */
td.ui-datepicker-today a.ui-state-highlight{
	border: 1px solid #bfbfbf;
	background: #e5e5e5 !important;
	color: #191919 !important;
}

/* カレンダー 選択 */
td.ui-datepicker-week-end:first-child a.ui-state-default:hover,
td.ui-datepicker-week-end:last-child a.ui-state-default:hover,
td.ui-datepicker-today a.ui-state-highlight:hover,
.ui-widget-content .ui-state-active,
.ui-state-default.ui-state-active,
td.ui-datepicker-week-end:last-child a.ui-state-default.ui-state-active,
td.ui-datepicker-week-end:first-child a.ui-state-default.ui-state-active{
	background: #191919 !important;
	color: #fff !important;
}

/* カレンダー 閉じるボタン */
.ui-widget-content .ui-priority-primary{
	font-weight: normal !important;
}

/* カレンダー 今日ボタン */
.ui-widget-content .ui-priority-secondary{
	opacity: 1 !important;
}

/* カレンダー prev nextボタン */
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover{
	border: none !important;
	background: #e9e9e9;
	opacity: 1 !important;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover{
	top: 2px !important;
}
.ui-datepicker .ui-datepicker-prev-hover{
	left: 2px !important;
}
.ui-datepicker .ui-datepicker-next-hover{
	right: 2px !important;
}

/* カレンダー デフォルト */
.ui-state-default.ui-state-hover{
	background: #191919 !important;
	color: #fff !important;
	border: 1px solid #cccccc !important;
}

/* form style */
input{
	&[type="text"], &[type="email"], &[type="url"], &[type="data"], &[type="tel"],&[type="number"],&[type="password"]{
		border-bottom: 1px solid #b2b2b2;
		@include fz(14);
		background: #fff;
		@include mq(){
			@include fz(16);
		}
	}
	&::placeholder{
		color: #b2b2b2;
	}
	&:focus{
		border-bottom: 1px solid #191919;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button{
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"]{
	-moz-appearance: textfield;
}

select{
	width: 100%;
	padding: 10px 35px 10px 10px;
	margin: auto;
	@include fz(14);
	background: #fff;
	border: none;
	border-bottom: 1px solid #bfbfbf;
	border-radius: 0;
	&::placeholder{
		color: #b2b2b2;
	}
	@include mq(){
		@include fz(16);
	}
	&:focus{
		border-bottom: 1px solid #191919;
	}
}

textarea{
	width: 100%;
	padding: 10px;
	margin: auto;
	@include fz(14);
	background: #fff;
	border: none;
	border: 1px solid #bfbfbf;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	resize: none;
	@include mq(){
		@include fz(16);
	}
	&:focus{
		border: 1px solid #191919;
	}
}

/* select */
select{
	color: #191919;
	option{
		color: #191919;
	}
}

.text-placeholder{
	color: #b2b2b2 !important;
}

.form-select{
	min-width: 120px;
	background: #fff;
}

button{
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.select-wrap{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		width: 8px;
		height: 8px;
		border-top: 1px solid #7f7f7f;
		border-right: 1px solid #7f7f7f;
		top: 14px;
		right: 12px;
		margin: auto;
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
		pointer-events: none;
	}
}

label,button{
	cursor: pointer;
}

input{
	&[type="checkbox"], &[type="radio"],&[type="submit"]{
		cursor: pointer;
	}
}
